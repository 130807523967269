export const pageTitle = 'Podświetlane kasetony reklamowe';
export const pageDescription = 'Nasze podświetlane kasetony wykonane z płyt kompozytowych Dibond to idealny pomysł na reklamę. Szyldy LED z literami 3D z pewnością przyciągną uwagę wielu klientów.';
export const mainHeader = 'Kaseton reklamowy';

export const cofferInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**<span class="orange">Czas realizacji</span>**
      \n 5 - 10 dni roboczych. Do czasu realizacji nie jest wliczany czas ewentualnego umówienia terminu montażu.`,
    },
  ],
  rightColumn: [
    {
      article: `## ${mainHeader}
      \n Niezwykle estetyczny i elegancki wygląd. Lico i boki wykonane z płyty kompozytowej Dibond w wybranych kolorze. Podświetlone są tylko wyfrezowane elementy graficzne na froncie. 
      Podklejenie frezu płytą plexi opal umożliwia podświetlenie w różnym kolorze.
      `,
    },
    {
      article: `**Specyfikacja**
      \n * Lico i boki: Płyta kompozytowa Dibond
      \n * Grubość kasetonu: 10cm
      \n * Plecy: Płyta PCV 5mm
      \n * Zasilanie i LED: Mean Well / Refond
      `,
    },
    {
      article: `**Opcje dodatkowe**
      \n * Czujnik zmierzchu:  \n Automatycznie włącza i wyłącza podświetlenie w zależności od pory dnia; 
      możliwość regulacji czułości czujnika - **<span class="orange">100 zł</span>**
      \n * Wypukłe litery 3D:  \n Litery, logo lub inne elementy wyfrezowane z oddzielnego kawałka pleksy o 
      gr. 10mm. Wystają delikatnie z kasetonu tworząc efekt 3D.  \n **<span class="orange">+ 30 % wartości kasetonu</span>**
      `,
    },
    {
      article: `**Inny wymiar?**
      \n Wycena indywidualna. Kasetony powyżej wymiaru 380x180 cm łączone są z dwóch lub więcej płyt.
      `,
    },
    {
      article: `**Informacje potrzebne przy zamówieniu**
      \n * wybrany rozmiar
      \n * kolor obudowy <a href="https://banerbunny.pl/szablony/kasetony/kolory-obudowy-wzornik.pdf" target="_blank" download>pobierz plik</a>
      \n * kolor świecenia <a href="https://banerbunny.pl/szablony/kasetony/kolory-swiecenia-wzornik.pdf" target="_blank" download>pobierz plik</a>
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">wymiary</span>**
      \n Najczęściej zamawiane wymiary kasetony to:
      \n * kaseton 200 x 60 cm
      \n * kaseton 300 x 60 cm
      \n * kaseton 600 x 60 cm
      \n * kaseton 200 x 80 cm
      \n * kaseton 300 x 80 cm
      \n * kaseton 600 x 80 cm
      `,
    },
  ],
};
